import React from 'react';
import visa01 from '../../../assets/img/Visa-services/visit-visa.jpg'
import visa02 from '../../../assets/img/Visa-services/student-visa.jpg'
import visa03 from '../../../assets/img/Visa-services/bussiness-visa.jpg'
import visa04 from '../../../assets/img/Visa-services/trasit-visa.jpg'

const VisaServices = () => {
  return (
    <section className="services" id="visa">
      <div className="container" data-aos="fade-up">

        <div className="section-title black-section-tiitle">
          <h2>Visa</h2>
          <h3><span>Fast</span> And <span>Reliable</span> Visa Service</h3>
          <p>Our expert team assists you with visa processing, making your international travel smooth and convenient.</p>
        </div>

        <div className="row">
          {/* Tourist Visa */}
          <div className="col-lg-3 col-md-6 d-flex align-items-stretch single-packeage each-visa-container" data-aos="zoom-in" data-aos-delay="100">
            <div className="visa-services">
              <div className="vs-img">
                <img src={visa01} alt="visa-services-01" />
              </div>
              <div className="vs-content">
                <h2>Tourist Visa</h2>
                <a href="https://wa.me/94779043979">Contact Us</a>
              </div>
            </div>
          </div>

          {/* Student Visa */}
          <div className="col-lg-3 col-md-6 d-flex align-items-stretch single-packeage each-visa-container" data-aos="zoom-in" data-aos-delay="100">
            <div className="visa-services">
              <div className="vs-img">
                <img src={visa02} alt="visa-services-02" />
              </div>
              <div className="vs-content">
                <h2>Student Visa</h2>
                <a href="https://wa.me/94779043979">Contact Us</a>
              </div>
            </div>
          </div>

          {/* Business Visa */}
          <div className="col-lg-3 col-md-6 d-flex align-items-stretch single-packeage each-visa-container" data-aos="zoom-in" data-aos-delay="100">
            <div className="visa-services">
              <div className="vs-img">
                <img src={visa03}alt="visa-services-03" />
              </div>
              <div className="vs-content">
                <h2>Business Visa</h2>
                <a href="https://wa.me/94779043979">Contact Us</a>
              </div>
            </div>
          </div>

          {/* Transit Visa */}
          <div className="col-lg-3 col-md-6 d-flex align-items-stretch single-packeage each-visa-container" data-aos="zoom-in" data-aos-delay="100">
            <div className="visa-services">
              <div className="vs-img">
                <img src={visa04} alt="visa-services-04" />
              </div>
              <div className="vs-content">
                <h2>Transit Visa</h2>
                <a href="https://wa.me/94779043979">Contact Us</a>
              </div>
            </div>
          </div>

        </div>

      </div>
    </section>
  );
};

export default VisaServices;
