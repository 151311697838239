import React from 'react';

const Footer = () => {
  return (
    <footer id="footer">
      <div className="footer-top">
        <div className="container">
          <div className="row">

            <div className="col-lg-3 col-md-6 footer-contact">
              <h3>BizLand<span>.</span></h3>
              <p>
                No. 636, 6th floor,<br />
                Jana Jaya City Rajagiriya<br />
                Sri Lanka.<br /><br />
                <strong>Phone:</strong> 0779043979 | 0779043802<br />
                <strong>Email:</strong> info@dreamdtourism.com  <br />
                <strong style={{display : 'none'}} >Email:</strong>dreamdtourism@outlook.com <br /> 
              </p>
            </div>

            <div className="col-lg-3 col-md-6 footer-links">
              <h4>Useful Links</h4>
              <ul>
                <li><i className="bx bx-chevron-right"></i> <a href="#banner">Home</a></li>
                <li><i className="bx bx-chevron-right"></i> <a href="#about">About us</a></li>
                <li><i className="bx bx-chevron-right"></i> <a href="#contact">Contact</a></li>
                <li><i className="bx bx-chevron-right"></i> <a href="#services">Tour Packages</a></li>
                <li><i className="bx bx-chevron-right"></i> <a href="#visa">Visa Services</a></li>
              </ul>
            </div>

            <div className="col-lg-3 col-md-6 footer-links">
              <h4>Why Dream Destination</h4>
              <ul>
                <li><i className="bx bx-chevron-right"></i> <a href="#whowe">Who We Are</a></li>
                <li><i className="bx bx-chevron-right"></i> <a href="#vission">Visson</a></li>
                <li><i className="bx bx-chevron-right"></i> <a href="#mission">Misson</a></li>
              </ul>
            </div>

            <div className="col-lg-3 col-md-6 footer-links">
              <h4>Our Social Networks</h4>
              <p>We love connecting with our travel community! Follow us on social media for the latest updates on tours, exciting travel news</p>
              <div className="social-links mt-3">
                <a href="https://www.facebook.com/dreamdtourism" className="facebook"><i className="bx bxl-facebook"></i></a>
                <a href="https://www.instagram.com/dreamdtourism/" className="instagram"><i className="bx bxl-instagram"></i></a>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div className="container py-4">
        <div className="copyright">
          &copy; Copyright <strong><a href="https://wa.me/94764063897">Smart Graphicz</a></strong>. All Rights Reserved
        </div>
        <div className="credits">
          Designed by <a href="https://umairlafir.com">Mohamad Lafir Umair</a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
