import React from "react";
import "./SideBar.css";
import logo from "../../../../assets/img/loader.png";
import {AiOutlineDashboard  , AiOutlineHeart} from 'react-icons/ai'
import {FaCar , FaTelegramPlane} from 'react-icons/fa'
import {Link} from 'react-router-dom'
import { RiAdminFill } from "react-icons/ri";
import { useDreamTourismContext } from "../../../../context/DreamTourismContext";
import { MdFlight } from "react-icons/md";
import { LiaCcVisa } from "react-icons/lia";

function SideBar({page ,dashbord , visa}) {
  const {user , adSideBar , adSideBarMobile , setPopupStatus  , adSideBarStatusMobile } = useDreamTourismContext()

  const handleNavigation = () => {
    setPopupStatus(false)
    adSideBarStatusMobile(false)
  }

  return (
    <div className={`admin-sidebar ${adSideBar && 'admin-sidebar-close'} ${adSideBarMobile  && 'admin-sidebar-open'}` }>
        <div className="as-inner-conatiner">

            <div className="sidebar-admin-head">
                <div className="sa-img">
                     <img src={logo} alt="logo" />
                </div>
                <div className="sa-contnet" >
                    <h3>Dream D Tourism</h3>
                    <p>info@dreamdtourism.com</p>
                </div>
            </div>
            
            <div className="main-links" >
                    <Link onClick={handleNavigation} className={` ${page === 'Tour Packages' && 'active-ap'}`} to={dashbord}>
                        <MdFlight />
                        <span>Tour Packages</span>
                    </Link>
                    <Link onClick={handleNavigation} className={` ${page === 'Visa Successs' && 'active-ap'}`} to={visa}>
                        <LiaCcVisa />
                        <span>Visa Success</span>
                    </Link>
                    {/* <Link onClick={handleNavigation} className={` ${page === 'vehicales' && 'active-ap'}`} to={vehicale}> 
                        <FaCar />
                        <span>Vehicales</span>
                    </Link>
                    <Link onClick={handleNavigation} className={` ${page === 'favourities' && 'active-ap'}`} to={favourite}>
                        <AiOutlineHeart />
                        <span>Favourities</span>
                    </Link>
                    <Link onClick={handleNavigation} className={` ${page === 'requests' && 'active-ap'}`}  to={request} >
                        <FaTelegramPlane />
                        <span>Requests</span>
                    </Link> */}
            </div>

        </div>
    </div>
  );
}

export default SideBar;
