import React from 'react';
import bg01 from '../../../assets/img/Places/Malayisa.jpg'
import bg02 from '../../../assets/img/Places/Thailand.jpg'
import bg03 from '../../../assets/img/Places/Dubai.jpg'
import bg04 from '../../../assets/img/Places/Singapore.jpg'
import {useDreamTourismContext} from '../../../context/DreamTourismContext'
import { TailSpin } from 'react-loader-spinner';


const Services = () => {
  const { tourPackages } = useDreamTourismContext()

  return (
    <section id="services" className="services">
      <div className="container" data-aos="fade-up">

        <div className="section-title black-section-tiitle">
          <h2>Tour</h2>
          <h3>Amazing <span>Tour</span> Packages</h3>
          <p>Travel to your Dream Destination with Dream Destination Tourism.</p>
        </div>

        <div className='row' >
        {
          tourPackages ? 
          (
            tourPackages?.map((pkg) => (
              <div className="col-lg-4 col-md-6 d-flex align-items-stretch single-packeage" >
                <div style={{ backgroundImage: `url(${pkg?.image})` }} className="each-package-container">
                  <div className="packge-hedding">
                    <span>{pkg?.country}</span>
                  </div>
                  <div className="content">
                    <h3>{pkg?.night} NIGHTS / {pkg?.days} DAYS</h3>
                    <div className="package-details">
                      <h6>Package Details</h6>
                      <ul>
                      {pkg?.packageDescription.split(',').map((desc, index) => (
                        <li key={index}>{desc}</li>
                      ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            ))
          )   
          :
          (
            <div className='serive-loader' >
                <TailSpin
                  height="40"
                  width="40"
                  color="#dc1a22"
                  ariaLabel="tail-spin-loading"
                  radius="1"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
              />
            </div>
          )
        }
        </div>

      </div>
    </section>
  );
};

export default Services;
