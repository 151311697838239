import React, { useEffect, useState } from 'react'
import PreLoader from '../components/PreLoader/PreLoader'
import {motion} from 'framer-motion'
import SideBar from '../components/SideBar/SideBar';
import './AdminHome.css'
import Nav from '../components/Nav/Nav';
import {AiOutlineDashboard} from 'react-icons/ai'
import ShowaCaseCount from './components/ShowaCaseCount/ShowaCaseCount';
import ShowCaseVehicale from './components/ShowCaseVehicale/ShowCaseVehicale';
import PopupBlack from '../components/PopupBlack/PopupBlack';
import ViewdVehicale from '../components/ViewdVehicale/ViewdVehicale';
import DeleteConformation from '../components/DeleteConformation/DeleteConformation';
import Notification from '../../Home/components/Notification/Notification';
import { useDreamTourismContext } from '../../../context/DreamTourismContext';

function VisaSuccess() {
  const [isLoading, setIsLoading] = useState(true);
  const {adSideBar  ,  setPopupStatus , adSideBarStatusMobile} = useDreamTourismContext()

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  }, []);

  const handleSidebar = () => {
    setPopupStatus(false)
    adSideBarStatusMobile(false)
  }

  return (
    <>
       {
        isLoading ?
        <PreLoader />
        :
        <motion.div animate={{opacity:1 }} exit={{opacity:0}} initial={{opacity:0 }} transition={{duration:0.5}}>
           <SideBar page='Visa Successs' dashbord='../admin/adminpanel/tourpackages' visa='./'/>
           <ViewdVehicale />  
           <DeleteConformation />
           <Notification /> 
           <div className={`main-admin-home ${adSideBar  && 'main-admin-home-full'}`} >
                <Nav />
                <div className='main-admin-innner' >
                    <ShowaCaseCount />
                    <ShowCaseVehicale /> 
                </div>
           </div>
           <PopupBlack handleSidebar={handleSidebar} />
        </motion.div>
       }
      
    </>
  )
}

export default VisaSuccess