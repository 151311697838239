import React from 'react';
import TopBar from './components/TopBar';
import Header from './components/Header';
import Banner from './components/Banner';
import About from './components/About';
import Services from './components/Services';
import VisaServices from './components/VisaServices.js';
import Gallery from './components/Gallery.js';
import VisaSuccess from './components/VisaSuccess.js';
import AirTicketing from './components/AirTicketing.js';
import Contact from './components/Contact.js';
import Footer from './components/Footer.js';
import PreLoader from './components/PreLoader.js';
import Notification from './components/Notification/Notification.js';


const Home = () => {
  return (
    <>
        <TopBar />
        <Header />
        <Banner />
        <About />
        <Services />
        <VisaServices />
        <Gallery />
        <VisaSuccess />
        <AirTicketing />
        <Contact />
        <Footer />
        <PreLoader />
        <Notification />
        
    </>
  )
}

export default Home