import React, { useEffect, useRef, useState } from 'react';
import logo from '../../../assets/img/Logo.png';

const Header = () => {
    const headerRef = useRef(null);
    const navbarRef = useRef(null);
    const navbarLinksRef = useRef([]); // Define navbarLinksRef for storing navbar links
    const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
    const [activeLink, setActiveLink] = useState('#banner'); // Initialize with the default active link

    const handleMobileNavOpen = () => {
        setIsMobileNavOpen(!isMobileNavOpen);
    };

    const handleMobileNavClose = () => {
        setIsMobileNavOpen(false);
    };

    const handleDropdownToggle = (e) => {
        if (isMobileNavOpen) {
            e.preventDefault();
            const dropdown = e.target.nextElementSibling;
            dropdown.classList.toggle('dropdown-active');
        }
    };

    const handleScroll = () => {
        let position = window.scrollY + 200;
        navbarLinksRef.current.forEach(navbarlink => {
            if (!navbarlink.hash) return;
            let section = document.querySelector(navbarlink.hash);
            if (!section) return;
            if (position >= section.offsetTop && position <= (section.offsetTop + section.offsetHeight)) {
                setActiveLink(navbarlink.hash); // Update active link state
            }
        });
    };

    useEffect(() => {
        const navbarLinks = Array.from(navbarLinksRef.current);

        // Initial active state on load
        handleScroll();

        // Event listeners for load and scroll
        window.addEventListener('load', handleScroll);
        window.addEventListener('scroll', handleScroll);

        // Cleanup event listeners on unmount
        return () => {
            window.removeEventListener('load', handleScroll);
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        const header = headerRef.current;
        if (!header) return;

        const headerOffset = header.offsetTop;
        const nextElement = header.nextElementSibling;

        const handleHeaderFixed = () => {
            if (window.scrollY >= headerOffset) {
                header.classList.add('fixed-top');
                if (nextElement) nextElement.classList.add('scrolled-offset');
            } else {
                header.classList.remove('fixed-top');
                if (nextElement) nextElement.classList.remove('scrolled-offset');
            }
        };

        // Event listeners for load and scroll
        window.addEventListener('load', handleHeaderFixed);
        window.addEventListener('scroll', handleHeaderFixed);

        // Cleanup event listeners on unmount
        return () => {
            window.removeEventListener('load', handleHeaderFixed);
            window.removeEventListener('scroll', handleHeaderFixed);
        };
    }, []);

    return (
        <header id="header" className={`d-flex align-items-center`} ref={headerRef}>
            <div className="container d-flex align-items-center justify-content-between">
                <a href="index.html" className="logo">
                    <img src={logo} alt="" />
                </a>

                <nav id="navbar" className={`navbar ${isMobileNavOpen ? 'navbar-mobile' : ''}`} ref={navbarRef}>
                    <ul>
                        <li><a onClick={handleMobileNavClose} ref={el => navbarLinksRef.current[0] = el} className={`nav-link scrollto ${activeLink === '#banner' ? 'active' : ''}`} href="#banner">Home</a></li>
                        <li><a onClick={handleMobileNavClose} ref={el => navbarLinksRef.current[1] = el} className={`nav-link scrollto ${activeLink === '#about' ? 'active' : ''}`} href="#about">About</a></li>
                        <li><a onClick={handleMobileNavClose} ref={el => navbarLinksRef.current[2] = el} className={`nav-link scrollto ${activeLink === '#services' ? 'active' : ''}`} href="#services">Tour Packages</a></li>
                        <li><a onClick={handleMobileNavClose} ref={el => navbarLinksRef.current[3] = el} className={`nav-link scrollto ${activeLink === '#visa' ? 'active' : ''}`} href="#visa">Visa Services</a></li>
                        <li><a onClick={handleMobileNavClose} ref={el => navbarLinksRef.current[4] = el} className={`nav-link scrollto ${activeLink === '#Air_ticketing' ? 'active' : ''}`} href="#Air_ticketing">Air Ticket</a></li>
                        <li><a onClick={handleMobileNavClose} ref={el => navbarLinksRef.current[5] = el} className={`nav-link scrollto ${activeLink === '#contact' ? 'active' : ''}`} href="#contact">Contact</a></li>
                    </ul>
                    <i className={`bi ${isMobileNavOpen ? 'bi-x' : 'bi-list'} mobile-nav-toggle`} onClick={handleMobileNavOpen}></i>
                </nav>
            </div>
        </header>
    );
};

export default Header;
