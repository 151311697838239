import React from 'react';

import {Swiper , SwiperSlide} from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import gallery01 from '../../../assets/img/flags/1.webp'
import gallery02 from '../../../assets/img/flags/2.webp'
import gallery03 from '../../../assets/img/flags/3.webp'
import gallery04 from '../../../assets/img/flags/4.webp'
import gallery05 from '../../../assets/img/flags/5.webp'
import gallery06 from '../../../assets/img/flags/6.png'
import gallery07 from '../../../assets/img/flags/7.jpg'
import gallery08 from '../../../assets/img/flags/8.webp'
import gallery09 from '../../../assets/img/flags/9.webp'
import gallery10 from '../../../assets/img/flags/10.webp'
import gallery11 from '../../../assets/img/flags/11.jpg'
import gallery12 from '../../../assets/img/flags/12.webp'
import gallery13 from '../../../assets/img/flags/13.webp'

const Gallery = () => {
  // useEffect(() => {
  //   const swiper = new Swiper('.gallery-slider', {
  //     speed: 400,
  //     loop: true,
  //     centeredSlides: true,
  //     autoplay: {
  //       delay: 5000,
  //       disableOnInteraction: false
  //     },
  //     slidesPerView: 'auto',
  //     pagination: {
  //       el: '.swiper-pagination',
  //       type: 'bullets',
  //       clickable: true
  //     },
  //     breakpoints: {
  //       320: {
  //         slidesPerView: 1,
  //         spaceBetween: 20
  //       },
  //       575: {
  //         slidesPerView: 2,
  //         spaceBetween: 20
  //       },
  //       768: {
  //         slidesPerView: 3,
  //         spaceBetween: 20
  //       },
  //       992: {
  //         slidesPerView: 5,
  //         spaceBetween: 25
  //       }
  //     }
  //   });

  //   return () => {
  //     swiper.destroy(); // Clean up Swiper instance when component unmounts
  //   };
  // }, []); // Empty dependency array ensures effect runs only once

  return (
    <section>
      <div className="container" data-aos="fade-up">
        <div className="section-title black-section-tiitle">
          <h3><span>Countries</span> We Do Visa</h3>
        </div>
        <div className="row">
          <div className="gallery-slider swiper">
            <Swiper
              spaceBetween={10}
              slidesPerView={5}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },
                400:{
                  slidesPerView:2,
                },
                639: {
                  slidesPerView: 3,
                },
                865:{
                  slidesPerView:4
                },
                1000:{
                  slidesPerView:5
                },
                1500:{
                  slidesPerView:6
                },
                1700:{
                  slidesPerView:7
                }
              }}
              modules={[Autoplay]}
              loop={true}
              autoplay={{
                    delay: 1200,
                    disableOnInteraction: false
              }}
              pagination
            className="swiper-wrapper align-items-center">
              <SwiperSlide className="swiper-slide each-country"><img src={gallery03} className="img-fluid" alt="" /></SwiperSlide>
              <SwiperSlide className="swiper-slide each-country"><img src={gallery02} className="img-fluid" alt="" /></SwiperSlide>
              <SwiperSlide className="swiper-slide each-country"><img src={gallery04} className="img-fluid" alt="" /></SwiperSlide>
              <SwiperSlide className="swiper-slide each-country"><img src={gallery01} className="img-fluid" alt="" /></SwiperSlide>
              <SwiperSlide className="swiper-slide each-country"><img src={gallery05} className="img-fluid" alt="" /></SwiperSlide>
              <SwiperSlide className="swiper-slide each-country"><img src={gallery06} className="img-fluid" alt="" /></SwiperSlide>
              <SwiperSlide className="swiper-slide each-country"><img src={gallery07} className="img-fluid" alt="" /></SwiperSlide>
              <SwiperSlide className="swiper-slide each-country"><img src={gallery08} className="img-fluid" alt="" /></SwiperSlide>
              <SwiperSlide className="swiper-slide each-country"><img src={gallery09} className="img-fluid" alt="" /></SwiperSlide>
              <SwiperSlide className="swiper-slide each-country"><img src={gallery10} className="img-fluid" alt="" /></SwiperSlide>
              <SwiperSlide className="swiper-slide each-country"><img src={gallery11} className="img-fluid" alt="" /></SwiperSlide>
              <SwiperSlide className="swiper-slide each-country"><img src={gallery12} className="img-fluid" alt="" /></SwiperSlide>
              <SwiperSlide className="swiper-slide each-country"><img src={gallery13} className="img-fluid" alt="" /></SwiperSlide>
            </Swiper>
            <div className="swiper-pagination pagination-dream-d-tourism"></div>
          </div>
          <div className="exquiry">
            <a href="https://wa.me/94779043979">For Enquiry</a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Gallery
