import React from 'react'

const TopBar = () => {
  return (
    <section id="topbar" className="d-flex align-items-center topbar-container"> 
            <div className="container d-flex justify-content-center justify-content-md-between">
            <div className="contact-info d-flex align-items-center">
                <i className="bi bi-envelope d-flex align-items-center">
                <a href="mailto:info@dreamdtourism.com">info@dreamdtourism.com</a>
                </i>
                <i className="bi bi-phone d-flex align-items-center ms-4">
                <span> +94 77 904 3979 | +94 77 904 3802 </span>
                </i>
            </div>
            <div className="social-links d-none d-md-flex align-items-center">
                <a href="https://www.facebook.com/dreamdtourism" className="facebook">
                <i className="bi bi-facebook"></i>
                </a>
                <a href="https://www.instagram.com/dreamdtourism/" className="instagram">
                <i className="bi bi-instagram"></i>
                </a>
            </div>
            </div>
    </section>
  )
}

export default TopBar