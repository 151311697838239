import React, { useEffect, useState } from 'react'
import {Navigate  , Outlet} from 'react-router-dom'
import { useDreamTourismContext } from '../context/DreamTourismContext'

function PrivateRoute() {
  const {isLoged} = useDreamTourismContext()
  const [showRoute  , setShowRoute] = useState(false)

  useEffect(() => {
      setTimeout(() => {
         setShowRoute(true)
      },1200)
  },[])

  return (
    <>
    {
      showRoute && (
         isLoged ? <Outlet /> : <Navigate to='/admin/adminpanel/login' />
      )
    }
    
    </>
    
  )
}

export default PrivateRoute