import React from 'react';

import airlines01 from '../../../assets/img/Airlines/ailines01.png'
import airlines02 from '../../../assets/img/Airlines/ailines02.png'
import airlines03 from '../../../assets/img/Airlines/ailines03.png'
import airlines04 from '../../../assets/img/Airlines/ailines04.png'
import airlines05 from '../../../assets/img/Airlines/ailines05.png'
import airlines06 from '../../../assets/img/Airlines/ailines06.png'

const AirTicketing= () => {
  return (
    <section id="Air_ticketing" className="services">
      <div className="container" data-aos="fade-up">

        <div className="section-title black-section-tiitle">
          <h2>Air Ticket</h2>
          <h3>Ticketing Service <span>All Around</span> The World</h3>
          <p>We provide seamless booking and ticketing services for flights, ensuring a hassle-free travel experience.</p>
        </div>

        <div className="row airlines-row">
          <div className="airline-image-conatiner">
            <img src={airlines01} alt="airline-01" />
          </div>
          <div className="airline-image-conatiner">
            <img src={airlines02} alt="airline-02" />
          </div>
          <div className="airline-image-conatiner">
            <img src={airlines03} alt="airline-03" />
          </div>
          <div className="airline-image-conatiner">
            <img src={airlines04} alt="airline-04" />
          </div>
          <div className="airline-image-conatiner">
            <img src={airlines05} alt="airline-05" />
          </div>
          <div className="airline-image-conatiner">
            <img src={airlines06} alt="airline-06" />
          </div>
        </div>

      </div>
    </section>
  );
};

export default AirTicketing;
