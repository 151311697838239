import React from 'react'
import './PreLoader.css'
import logo from '../../../../assets/img/loader.png'
import {ThreeDots} from 'react-loader-spinner'
import {motion} from 'framer-motion'

function PreLoader() {
  return (
    <motion.div className='preloader-admin' animate={{opacity:1 }} exit={{opacity:0}} initial={{opacity:0 }} transition={{duration:0.5}} >
        <span className='red-blur' ></span>
        <span className='orange-blur' ></span>
        <div className='preloader-content' >
            <img src={logo} alt='logo' />
            <ThreeDots 
                height="80" 
                width="80" 
                radius="9"
                color="#B1252E" 
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
            />
        </div>
    </motion.div>
  )
}

export default PreLoader