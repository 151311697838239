import React from 'react'
import './Popupblack.css'
import { useDreamTourismContext } from '../../../../context/DreamTourismContext'

function PopupBlack({handleSidebar}) {
 const {popupStatus} = useDreamTourismContext()

 console.log(popupStatus);

  return (
    <div onClick={handleSidebar} className={`Popup ${popupStatus && 'popup-open'}`} >

    </div>
  )
}

export default PopupBlack