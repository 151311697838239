import React, { useState , useRef, useEffect } from 'react'
import './ShowaCaseCount.css'
import {BsImages} from 'react-icons/bs'
import {FiUpload} from 'react-icons/fi'
import { db, storage } from '../../../../../firebase-config'
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
import { TailSpin } from  'react-loader-spinner'
import { Timestamp, addDoc, collection, doc, updateDoc } from 'firebase/firestore'
import { useDreamTourismContext } from '../../../../../context/DreamTourismContext'
import { MdDeleteOutline } from 'react-icons/md'


function ShowaCaseCount() {
    const [selectedImage , setSelectedImage]  = useState([])
    const [imageUploadingStatus  , setImageUploadingStatus] = useState(false)


    const {setNotiStatus, setNotiError, setNotiContent , updatedTourPackages }  = useDreamTourismContext() 

    const photoRef = useRef()
    const visaSuccessCollection = collection(db , 'visasuccess')  

    const handleUploadVisaSuccess = (url  , index  ,imgLength  ) => {
        addDoc(visaSuccessCollection, {
            image : url,
            creatDate:Timestamp.now().toDate()
        }).then((res) => {
            console.log(res);
            setNotiContent(`Visa Success ${index + 1} Added Successfully`) 
            setNotiStatus(true)
            setNotiError(false)
            setTimeout(() =>{
                if((index + 1) === imgLength){
                    console.log("finish");
                    setImageUploadingStatus(false)
                    setSelectedImage([null])
                }
                setNotiStatus(false);
                setNotiError(false)                        
            },1500)
        }).catch((err) => {
            console.log(err);
            setNotiContent("Something Went Wrong Please Try Again Later") 
            setNotiStatus(true)
            setNotiError(true)
            setTimeout(() =>{
                setImageUploadingStatus(false)
                setNotiStatus(false);
                setNotiError(false)
            },4000)
        })
    }

    const handleImageUpload = (e) => {
        const imageFiles = e.target.files;
        const imgLength = imageFiles.length
        setImageUploadingStatus(true);
    
        Array.from(imageFiles).forEach((imageFile , index) => {
            const stoarageRef = ref(storage , `/visasuccess/${Date.now()}${imageFile.name}` )
            const uploadImage = uploadBytesResumable(stoarageRef , imageFile)
            uploadImage.on("state_changed" , () => {

                },(error) =>{
                    console.log(error)
                },() => {
                    getDownloadURL(uploadImage.snapshot.ref).then((url) => {
                        if(url){
                            setSelectedImage((prevList) => {
                                const updatedList = [...prevList];
                                updatedList[index] = url;
                                return updatedList;
                            });
                            console.log(url);
                            handleUploadVisaSuccess(url  , index , imgLength)
                        }
                        }).catch((err) => {
                            console.log(err);
                            setNotiContent("Something Went Wrong Please Try again Later") 
                            setNotiStatus(true)
                            setNotiError(true)
                            setTimeout(() =>{
                                setNotiStatus(false);
                                setNotiError(false)
                            },4000)
                        })
                }
            )
        });
    };
    


    const handleFileOpen = () => {
        photoRef.current.click()
    }

    const removeImage = (imageToRemove) => {
        setSelectedImage(prevList => prevList.filter(image => image !== imageToRemove));
        setNotiContent("Image Has Been Removed") 
        setNotiStatus(true)
        setNotiError(true)
        setTimeout(() =>{
            setNotiStatus(false);
            setNotiError(false)
        },4000)
    };

  

    return (
        <div className='vehical-add-form-container' >    
                    <div className='photo-upload-container  custom-selector' >
                            <label>Add Visa Success Story</label>
                            <div  className='photo-upload-container-main visa-succ-phot-conatiner'>
                                <input type='file' hidden ref={photoRef} onChange={(e) => handleImageUpload(e)} multiple />
                                {
                                    !imageUploadingStatus  ? (
                                        <div onClick={handleFileOpen} className='photo-ippload-container'>
                                            <p><BsImages /><span>Adding Visa Success Story</span></p>
                                        </div> 
                                    ) 
                                    : 
                                    (
                                        <div className='photo-uploading-container' >
                                                <TailSpin
                                                height="40"
                                                width="40"
                                                color="#757F95"
                                                ariaLabel="tail-spin-loading"
                                                radius="1"
                                                wrapperStyle={{}}
                                                wrapperClass=""
                                                visible={true}
                                            />
                                            <p>Wait Few Minutes</p>
                                        </div>
                                    )
                                }
                                
                            </div>
                    </div>

                    {
                        (selectedImage?.length > 0 && imageUploadingStatus) && (
                            <div className='uploaded-image-container'>
                                {selectedImage?.map((img, index) => (
                                    <div key={index} className='each-image-uploaded'>
                                        <img src={img} alt={`uploaded-img-${index}`} />
                                        
                                    </div>
                                ))}
                            </div>
                        )
                    }
                    
        </div>
    )
}

export default ShowaCaseCount