import React, { useEffect } from 'react';

const PreLoader = () => {
  useEffect(() => {
    const preloader = document.getElementById('preloader');
    if (preloader) {
      window.addEventListener('load', () => {
        preloader.remove();
      });
    }
  },[]);

  return (
    <div id="preloader"></div>
  );
}

export default PreLoader;
