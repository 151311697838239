import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyAKhmAoQrHMeIpXZpdrqK4LWWf0KTHMtxw",
  authDomain: "dreamtourism-b652d.firebaseapp.com",
  projectId: "dreamtourism-b652d",
  storageBucket: "dreamtourism-b652d.appspot.com",
  messagingSenderId: "896015517589",
  appId: "1:896015517589:web:d410fafaba0b08b46b989c"
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);

console.log(auth);

