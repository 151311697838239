import React from 'react'
import './DeleteConformation.css'
import {MdOutlineCancel} from 'react-icons/md'
import { BiTrashAlt } from 'react-icons/bi'
import {  RxCross2} from 'react-icons/rx'
import {  doc, deleteDoc} from 'firebase/firestore'
import { db } from '../../../../firebase-config'
import { useDreamTourismContext } from '../../../../context/DreamTourismContext'

function DeleteConformation() {
  const {delteconfomationStatus ,whichDelete , viewdVisaSuccess ,  setDeleteConfomrationStatus , viewdTourPackages  ,setUpdatedTourPackages ,  updatedTourPackages , setPopupStatus ,  setViewdTourPackages ,  setNotiContent , setNotiStatus , setNotiError} = useDreamTourismContext()

  const handleCloseDeleteBox = () => {
    setDeleteConfomrationStatus(false)
    setPopupStatus(false)
    setViewdTourPackages(null)
  }

  const handleReload = () => {
    window.location.reload();
  };


  const handleDleteProduct = async () => {
    if(whichDelete === 'vs'){
      const singleVs = doc(db ,'visasuccess' , viewdVisaSuccess?.id )
      await deleteDoc(singleVs).then(() => {
          setNotiContent("Visa Success Deleted Successfully") 
          setNotiStatus(true)
          setNotiError(false)
          setTimeout(() =>{
              setNotiStatus(false);
              setNotiError(false)
              
          },1500)
          setDeleteConfomrationStatus(false)
          setPopupStatus(false)
         
      })
      
    }else if(whichDelete === 'pkg'){
      const singlePkg = doc(db ,'packages' , viewdTourPackages ?.id )
      await deleteDoc(singlePkg).then(() => {
          handleReload()
          setNotiContent("Tour Pacakage Deleted Successfully") 
          setNotiStatus(true)
          setNotiError(false)
          setTimeout(() =>{
              setNotiStatus(false);
              setNotiError(false)
          },500)
          setDeleteConfomrationStatus(false)
          setPopupStatus(false)
      })
    }
   
  }

  return (
    <div className={`delete-conformation-full-container ${delteconfomationStatus && 'delete-conformation-full-container-open'}`}>
        <div className='delete-conformation-container' >
            <div className='delete-conformation-inner-container' >
                <MdOutlineCancel className='delte-confomation-svg'/>
                {
                  whichDelete == 'vs' && (<p>Do you really want to delete this Visa Success ? Once if you delete this Visa Success you can't recover it </p>)
                }
                {
                  whichDelete == 'pkg' && (<p>Do you really want to delete this <span>{viewdTourPackages?.country} </span> Tour Package ? Once if you delete this Tour Package you can't recover it </p>)
                }
                
                <div className='button-continaer-viewd-vehicale delete-conformation-button-continer' >
                    <button onClick={handleDleteProduct} className='theme-btn viewd-btn Delete-btn'><BiTrashAlt /><span>Delete</span></button>
                    <button onClick={handleCloseDeleteBox} className='theme-btn viewd-btn Update-btn'><RxCross2/><span>Cancel</span></button>
                </div>
            </div>  
        </div>
    </div>
  )
}

export default DeleteConformation