import React from 'react'
import './ViewdVehicale.css'
import { BiImage,BiTrashAlt } from 'react-icons/bi'
import { MdOutlineColorLens } from 'react-icons/md'
import { RxCross2, RxUpdate } from 'react-icons/rx'
import {useNavigate} from 'react-router-dom'
import { useDreamTourismContext } from '../../../../context/DreamTourismContext'
import { PiEngineBold } from "react-icons/pi";
import { CiCreditCard1  } from "react-icons/ci";
import { IoIosColorPalette } from "react-icons/io";
import { IoSpeedometerSharp } from "react-icons/io5";
import { SiGoogleearthengine } from "react-icons/si";

function ViewdVehicale() {
  const {viewdVehicaleStatus , setViewdVehicaleStatus , viedVehicale , setDeleteConfomrationStatus} = useDreamTourismContext()
  const navigate = useNavigate()

  const handleViewd = () => {
    setViewdVehicaleStatus(false)
  }

  const handleUpdate = () => {
    setViewdVehicaleStatus(false)
    navigate(`../admin/adminpanel/vehicale/update-vehicale/${viedVehicale?.id}`)
  }

  const handleDeleteBoxOpen = () => {
    setViewdVehicaleStatus(false)
    setTimeout(() => {
        setDeleteConfomrationStatus(true)
    },200)
  }


  return (
    <div className={`viewd-vehicale-full-black ${viewdVehicaleStatus  && 'viewd-vehicale-full-black-open'}`} >
        <div onClick={handleViewd} className='cancel-viewd-vehicale' >
                <RxCross2 />
        </div>
        <div className={`container-viewd-vehicale `} >
            <div className='inner-container-viewd-vehicale' >
                {/* <h3><span>{viedVehicale?.Brand}</span><span> | </span><span>{viedVehicale?.Model}</span></h3> */}
                <div className='mr-options viewd-vehicale-option' >
                    <div className='mr-eash-option'>
                        <PiEngineBold />
                        <span>{viedVehicale?.EningineCapacity}</span>
                    </div>
                    <div className='mr-eash-option'>
                        <SiGoogleearthengine />
                        <span>{viedVehicale?.EngineModel}</span>
                    </div>
                    <div className='mr-eash-option'>
                        <IoIosColorPalette />
                        <span>{viedVehicale?.Colour}</span>
                    </div>
                    <div className='mr-eash-option'>
                        <IoSpeedometerSharp />
                        <span>{viedVehicale?.Mileage}</span>
                    </div>
                    <div className='mr-eash-option'>
                        <CiCreditCard1 />
                        <span>{viedVehicale?.ChaseNo}</span>
                    </div>
                    <div className='mr-eash-option'>
                        <MdOutlineColorLens />
                        <span>{viedVehicale?.Transmission}</span>
                    </div>
                </div>
                <div className='image-container-viewd-vehicale'>
                    <p><BiImage /><span>All Vehicale Images</span></p>
                    <div className='image-inner-container-viewd-vehicale' >
                        {
                            viedVehicale?.Images?.map((img , index) => (
                                <div key={index} className='each-viewed-vehicale' >
                                    <img src={img} alt='viewd-vehicale' />
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className='button-continaer-viewd-vehicale' >
                    <button onClick={handleDeleteBoxOpen} className='theme-btn viewd-btn Delete-btn'><BiTrashAlt /><span>Delete Vehicale</span></button>
                    <button onClick={handleUpdate} className='theme-btn viewd-btn Update-btn'><RxUpdate /><span>Update Vehicale</span></button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ViewdVehicale