import React, { useState , useRef, useEffect } from 'react'
import './ShowaCaseCount.css'
import { PiEngineBold } from "react-icons/pi";
import {BsImages} from 'react-icons/bs'
import {FiUpload} from 'react-icons/fi'
import {MdDeleteOutline} from 'react-icons/md'
import { db, storage } from '../../../../../firebase-config'
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
import { TailSpin } from  'react-loader-spinner'
import { Timestamp, addDoc, collection, doc, updateDoc } from 'firebase/firestore'
import { useDreamTourismContext } from '../../../../../context/DreamTourismContext'
import { BiTrashAlt } from 'react-icons/bi';
import { RxUpdate } from 'react-icons/rx';


function ShowaCaseCount() {
 

    const [selectedImage , setSelectedImage]  = useState()
    const [packagesDesc , setPackagesDesc]  = useState()
    const [days , setDays]  = useState()
    const [night , setNight]  = useState()
    const [country , setCountry]  = useState()
    const [imageUploadingStatus  , setImageUploadingStatus] = useState(false)
    const [VehicaleUploadingStatus  , setVehicaleUploadingStatus] = useState(false)

    const [value, setValue] = React.useState(0);
    const [clicked , setClicked]  = useState("");

    const {setNotiStatus, setNotiError, setNotiContent , updatedTourPackages , setUpdatedTourPackages  }  = useDreamTourismContext() 

    const photoRef = useRef()
    const vehicaleCollection = collection(db , 'packages')  

    useEffect(() => {
        console.log("tou"  , updatedTourPackages);
        setCountry(updatedTourPackages?.country)
        setPackagesDesc(updatedTourPackages?.packageDescription )
        setSelectedImage(updatedTourPackages?.image)
        setNight(updatedTourPackages?.night)
        setDays(updatedTourPackages?.days)
    }, [updatedTourPackages])

    const handleClicked = (selectedItem) => {
        setClicked(selectedItem)
        if(selectedItem === clicked ){
          setClicked("")
        }
    }

    const handleUploadVehicale = (vehiTYpe) => {
        setVehicaleUploadingStatus(true)
        console.log(selectedImage);
        console.log(country);
        console.log(packagesDesc);
            if(country && selectedImage && packagesDesc){
                console.log("Add doc");
                addDoc(vehicaleCollection , {
                    image : selectedImage,
                    country : country,
                    packageDescription : packagesDesc,
                    days: days,
                    night : night,
                    creatDate:Timestamp.now().toDate()
                }).then(() => {
                    handleScrollToBottom()
                    setNotiContent("Tour Package Added Successfully") 
                    setNotiStatus(true)
                    setNotiError(false)
                    setTimeout(() =>{
                        setVehicaleUploadingStatus(false)
                        setNotiStatus(false);
                        setNotiError(false)                        
                    },4000)
                    setCountry("")
                    setPackagesDesc("")
                    setSelectedImage("")
                    setDays("")
                    setNight("")
                    
                }).catch((err) => {
                    console.log(err);
                    setNotiContent("Something Went Wrong Please Try Again Later") 
                    setNotiStatus(true)
                    setNotiError(true)
                    setTimeout(() =>{
                        setVehicaleUploadingStatus(false)
                        setNotiStatus(false);
                        setNotiError(false)
                    },4000)
                })

            }else{
                if(!country){
                    setNotiContent("Please Enter Country") 
                    setNotiStatus(true)
                    setNotiError(true)
                    setTimeout(() =>{
                        setVehicaleUploadingStatus(false)
                        setNotiStatus(false);
                        setNotiError(false)
                    },4000)
                }
                if(!packagesDesc){
                    setNotiContent("Please Enter Package Desc") 
                    setNotiStatus(true)
                    setNotiError(true)
                    setTimeout(() =>{
                        setVehicaleUploadingStatus(false)
                        setNotiStatus(false);
                        setNotiError(false)
                    },4000)
                }
                if(!selectedImage){
                    setNotiContent("Please Upload Tour Package Image") 
                    setNotiStatus(true)
                    setNotiError(true)
                    setTimeout(() =>{
                        setVehicaleUploadingStatus(false)
                        setNotiStatus(false);
                        setNotiError(false)
                    },4000)
                }
            
                
            }
    }

    const updatePackage = () => {
        setVehicaleUploadingStatus(true);
        // Assuming updatePackage is an object with an id property
        const packageId = updatedTourPackages?.id; // Safely access id from updatePackage
        console.log(updatedTourPackages);
      
        if (packageId) {
          const singlePackage = doc(db, 'packages', packageId); // Use packageId here
          console.log(packageId);
          console.log(selectedImage);
          console.log(country);
          console.log(packagesDesc);
      
          // Continue with your logic
          if (country && selectedImage && packagesDesc) {
            updateDoc(singlePackage, {
              image: selectedImage,
              country: country,
              packageDescription: packagesDesc,
              days: days,
              night: night,
              creatDate: Timestamp.now().toDate()
            }).then(() => {
              // Success logic
              handleScrollToBottom()
              setNotiContent("Tour Package Updated Successfully");
              setVehicaleUploadingStatus(false);
              setNotiStatus(true);
              setNotiError(false);
              setTimeout(() => {
                
                setNotiStatus(false);
                setNotiError(false);
              }, 4000);
              // Reset state
              setCountry("");
              setPackagesDesc("");
              setSelectedImage("");
              setDays("");
              setNight("");
              setUpdatedTourPackages(null)
            }).catch((err) => {
              // Error handling
              console.log(err);
              setNotiContent("Something Went Wrong Please Try Again Later");
              setNotiStatus(true);
              setNotiError(true);
              setTimeout(() => {
                setVehicaleUploadingStatus(false);
                setNotiStatus(false);
                setNotiError(false);
              }, 4000);
            });
          } else {
            // Validation errors
            if (!country) {
              setNotiContent("Please Enter Country");
              setNotiStatus(true);
              setNotiError(true);
              setTimeout(() => {
                setVehicaleUploadingStatus(false);
                setNotiStatus(false);
                setNotiError(false);
              }, 4000);
            }
            if (!packagesDesc) {
              setNotiContent("Please Enter Package Desc");
              setNotiStatus(true);
              setNotiError(true);
              setTimeout(() => {
                setVehicaleUploadingStatus(false);
                setNotiStatus(false);
                setNotiError(false);
              }, 4000);
            }
            if (!selectedImage) {
              setNotiContent("Please Upload Tour Package Image");
              setNotiStatus(true);
              setNotiError(true);
              setTimeout(() => {
                setVehicaleUploadingStatus(false);
                setNotiStatus(false);
                setNotiError(false);
              }, 4000);
            }
          }
        } else {
          console.log("Package ID is undefined or null");
          // Handle case where updatePackage.id is not defined
        }
      };
      

    const handleImageUpload = (e) => {
        const imageFile = e.target.files[0]
        setImageUploadingStatus(true)
        const stoarageRef = ref(storage , `/packages/${Date.now()}${imageFile.name}` )
        const uploadImage = uploadBytesResumable(stoarageRef , imageFile)
        uploadImage.on("state_changed" , () => {

            },(error) =>{
                console.log(error)
            },() => {
                getDownloadURL(uploadImage.snapshot.ref).then((url) => {
                    if(url){
                        setImageUploadingStatus(false)
                        setNotiContent("Image Successfully Uploaded") 
                        setNotiStatus(true)
                        setNotiError(false)
                        setTimeout(() =>{
                            setNotiStatus(false);
                            setNotiError(false)
                        },4000)
                        setTimeout(() => {
                            setSelectedImage(url);
                        },4000)
                    }
                    }).catch((err) => {
                        console.log(err);
                        setNotiContent("Something Went Wrong Please Try again Later") 
                        setNotiStatus(true)
                        setNotiError(true)
                        setTimeout(() =>{
                            setNotiStatus(false);
                            setNotiError(false)
                        },4000)
                    })
            }
        )
    }

    const handleCountry = (e) => {
        setCountry(e.target.value)
    }

    const handleDays = (e) => {
        setDays(e.target.value)
    }

    const handleNight = (e) => {
        setNight(e.target.value)
    }

    const handlePackageDesc = (e) => {
        setPackagesDesc(e.target.value)
    }

    const handleFileOpen = () => {
        photoRef.current.click()
    }

    const handleClear = () => {
        setCountry("")
        setPackagesDesc("")
        setSelectedImage("")
        setDays("")
        setNight("")
        setUpdatedTourPackages(null)
    }

    const handleScrollToBottom = () => {
        window.scrollTo({ top: document.documentElement.scrollHeight, behavior: 'smooth' });
    };
  

    return (
        <div className='vehical-add-form-container' >    
                    <div className='vehicale-add-form-all-inputs'>
                        <div>
                            <div className='package-main' >
                                <div onClick={() => handleClicked("package")} className={`custom-selector ${clicked === "package" && 'clicked'}`} >
                                        <label>Country</label>
                                        <div className='year-input'  >
                                            <input value={country} type='text' placeholder='Enter Country' onChange={(e) => handleCountry(e)} />
                                            <PiEngineBold />
                                        </div>
                                </div>
                                <div onClick={() => handleClicked("days")} className={`custom-selector ${clicked === "days" && 'clicked'}`} >
                                        <label>Days</label>
                                        <div className='year-input'  >
                                            <input value={days} type='text' placeholder='Enter Days' onChange={(e) => handleDays(e)} />
                                            <PiEngineBold />
                                        </div>
                                </div>
                            </div>
                            <div className='custom-selector' >
                                <label>Package Detail</label>
                                <div className='text-area-container' >
                                    <textarea value={packagesDesc}  onChange={(e) => handlePackageDesc(e)} rows={13} cols={50} type='text' placeholder='Enter Package Description' className='px-4 py-4 text-[14px] text-[#757F95] w-full border-none outline-none placeholder:text-[#757F95]'  />
                                </div>
                                <p>*Please Put , end of each detail</p>
                            </div>
                        </div>
                        <div>
                        <div onClick={() => handleClicked("night")} className={`custom-selector ${clicked === "night" && 'clicked'}`} >
                                        <label>Nights</label>
                                        <div className='year-input'  >
                                            <input value={night} type='text' placeholder='Enter Nights' onChange={(e) => handleNight(e)} />
                                            <PiEngineBold />
                                        </div>
                                </div>
                        <div className='photo-upload-container custom-selector' >
                            <label>Upload Images</label>
                            <div  className='photo-upload-container-main'>
                                <input type='file' hidden ref={photoRef} onChange={(e) => handleImageUpload(e)} />
                                {
                                    !imageUploadingStatus  ? (
                                        <div onClick={handleFileOpen} className='photo-ippload-container'>
                                            <p><BsImages /><span>Upload Vehicale Images</span></p>
                                        </div> 
                                    ) 
                                    : 
                                    (
                                        <div className='photo-uploading-container' >
                                                <TailSpin
                                                height="40"
                                                width="40"
                                                color="#757F95"
                                                ariaLabel="tail-spin-loading"
                                                radius="1"
                                                wrapperStyle={{}}
                                                wrapperClass=""
                                                visible={true}
                                            />
                                            <p>Uploading Image</p>
                                        </div>
                                    )
                                }
                                
                            </div>
                        </div>
                        </div>

                    </div>
                    
            
                    {
                        selectedImage && (
                            <div className='uploaded-image-container' >
                                 <div className='each-image-uploaded' >
                                     <img src={selectedImage} alt='uploaded-img' />
                                </div>
                            </div>
                        )
                    }
                    {

                        updatedTourPackages ?
                        (
                            <div className='button-continaer-viewd-vehicale delete-conformation-button-continer' >
                                <button onClick={updatePackage}   className='theme-btn viewd-btn Update-btn admin-pkg-update-btn'>
                                {
                                    VehicaleUploadingStatus ?  
                                    (
                                        <div className='photo-uploading-container' >
                                                    <TailSpin
                                                    height="25"
                                                    width="25"
                                                    color="#FFF"
                                                    ariaLabel="tail-spin-loading"
                                                    radius="1"
                                                    wrapperStyle={{}}
                                                    wrapperClass=""
                                                    visible={true}
                                                />
                                        </div>
                                    )
                                    :
                                    (
                                        <>
                                           <RxUpdate /><span>Update</span>
                                        </>
                                    )
                                }
                                    
                                </button>
                                <button onClick={handleClear}  className='theme-btn viewd-btn Delete-btn admin-pkg-update-btn'><BiTrashAlt /><span>Clear</span></button>
                            </div>
                        )
                        :
                        (
                           <button onClick={() => handleUploadVehicale("Light-Vehicale")} className={`theme-btn admin-pkg-btn`}>
                                {
                                    VehicaleUploadingStatus ?  
                                    (
                                        <div className='photo-uploading-container' >
                                                    <TailSpin
                                                    height="25"
                                                    width="25"
                                                    color="#FFF"
                                                    ariaLabel="tail-spin-loading"
                                                    radius="1"
                                                    wrapperStyle={{}}
                                                    wrapperClass=""
                                                    visible={true}
                                                />
                                        </div>
                                    )
                                    :
                                    (
                                        <>
                                            <FiUpload /><span>Add Tour Package</span>  
                                        </>
                                    )
                                }
                            </button>
                        )

                    }
            
                    
        </div>
    )
}

export default ShowaCaseCount