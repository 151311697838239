import React from 'react';
import { useDreamTourismContext } from '../../../context/DreamTourismContext';

const VisaSuccess = () => {
  const { visaSuccess } = useDreamTourismContext()

  return (
    <section>
    <div className="container" data-aos="fade-up">

      <div className="section-title black-section-tiitle">
        <h3>Visa <span>Success</span> Story</h3>
      </div>

      <div className="row">
        {
          visaSuccess?.map((vs , index) => (
            <div key={index} className="col-lg-4 col-md-6 d-flex align-items-stretch single-packeage" data-aos="zoom-in" data-aos-delay="100" >
                <div className="visa-item single-packeage-first">
                  <img src={vs?.image} alt="visa-success-01" />
                </div>
            </div>
          ))
        }
    
      </div>


    </div>
  </section>
  );
};

export default VisaSuccess;
