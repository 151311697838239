import React from 'react'
import './ShowCaseVehicale.css'
import '../../../components/DeleteConformation/DeleteConformation.css'
import { useDreamTourismContext } from '../../../../../context/DreamTourismContext'
import { BiTrashAlt } from 'react-icons/bi';
import { RxCross2, RxUpdate } from 'react-icons/rx';


function ShowCaseVehicale() {
  const { tourPackages  , setUpdatedTourPackages , setWhichDelete ,  setViewdTourPackages ,setPopupStatus , setDeleteConfomrationStatus} = useDreamTourismContext()

  console.log(tourPackages);

  const handleDelete = (pkg) => {
    setWhichDelete("pkg")
    setViewdTourPackages(pkg)
    setDeleteConfomrationStatus(true)
    setPopupStatus(true)
  }


  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    console.log("scrolll top");
  };

  const handleUpdate = (pkg) => {
    setUpdatedTourPackages(pkg)
    console.log(pkg);
    scrollToTop()
  }

  return (
    <section id="services" className="services">
      <div className="container" data-aos="fade-up">

        <div className="section-title black-section-tiitle">
          <h2>Tour</h2>
          <h3>Amazing <span>Tour</span> Packages</h3>
          <p>Travel to your Dream Destination with Dream Destination Tourism.</p>
        </div>

        <div className="row-admin-services admin">
          
          {
            tourPackages?.map((pkg) => (
              <div className="admin-sing-img col-lg-4 col-md-6 d-flex align-items-stretch single-packeage" data-aos="zoom-in" data-aos-delay="100">
                <div style={{ backgroundImage: `url(${pkg?.image})` }} className="each-package-container">
                  <div className="packge-hedding">
                    <span>{pkg?.country}</span>
                  </div>
                  <div className="content">
                    <h3>{pkg?.night} NIGHTS / {pkg?.days} DAYS</h3>
                    <div className="package-details">
                      <h6>Package Details</h6>
                      <ul>
                      {pkg?.packageDescription.split(',').map((desc, index) => (
                        <li key={index}>{desc}</li>
                      ))}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className='button-continaer-viewd-vehicale delete-conformation-button-continer' >
                    <button onClick={() => handleDelete(pkg)}  className='theme-btn viewd-btn Delete-btn'><BiTrashAlt /><span>Delete</span></button>
                    <button onClick={() => handleUpdate(pkg)}  className='theme-btn viewd-btn Update-btn'><RxUpdate /><span>Update</span></button>
                </div>
              </div>
            ))
          }


        </div>

      </div>
    </section>
  )
}

export default ShowCaseVehicale