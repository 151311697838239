import React from 'react'

import about01 from '../../../assets/img/About/ABOUT 01.jpg'
import about02 from '../../../assets/img/About/ABOUT 02.webp'
import about03 from '../../../assets/img/About/ABOUT 03.jpg'
import aboutSvg01 from '../../../assets/img/SVG/DDSvg01.svg'
import aboutSvg02 from '../../../assets/img/SVG/DDSvg02.svg'
import aboutSvg03 from '../../../assets/img/SVG/DDSvg03.svg'

const About = () => {
  return (
    <section id="about" className="about section-bg">
      <div className="container" data-aos="fade-up">

        <div className="section-title">
          <h2>About</h2>
          <h3>TIME TO <span>TRAVEL</span> WITH US</h3>
          <p>World Wide Tour, Ticket & Visa</p>
        </div>

        <div className="about-us-container">
          <div className="about-us-each">

            <div id="whowe" className="about-us-each-inner">
              <img className="svg-image-about" src={aboutSvg01} alt="svg01" /> 
              <p>Dream Destination Tourism is a premier Sri Lankan company, known for its flexible travel services and customer-focused solutions. Committed to satisfaction, we offer diverse options that cater to unique preferences. Our experienced professionals guarantee a memorable, seamless travel experience from start to finish.</p>
              <div className="photo-container">
                <img src={about01} alt="about-img" />
              </div>
            </div>
            
            <div id="vission" className="about-us-each-inner">
              <img className="svg-image-about" src={aboutSvg02} alt="svg02" /> 
              <p>Our mission at Dream Destination Tourism is to make affordable travel services to customers and to deliver pleasurable travel experiences that are seamless from end to end. We offer hassle-free travel services from the moment you start planning your vacation. Together, we deliver joyful vacation experiences and breakthrough.</p>
              <div className="photo-container">
                <img src={about02} alt="about-img" />
              </div>
            </div>
            
            <div id="mission" className="about-us-each-inner">
              <img className="svg-image-about" src={aboutSvg03} alt="svg03" /> 
              <p>Embracing a forward stance, our vision is to be the ultimate preference for global travelers, offering exceptional service. Our dedication centers on curating enjoyable travel experiences, meticulously treating each inquiry with utmost attention, reflecting our unwavering commitment to excellence.</p>
              <div className="photo-container">
                <img src={about03} alt="about-img" />
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  )
}

export default About