import React from 'react'
import './ShowCaseVehicale.css'
import '../../../components/DeleteConformation/DeleteConformation.css'
import { useDreamTourismContext } from '../../../../../context/DreamTourismContext'
import { BiTrashAlt } from 'react-icons/bi';
import { RxCross2, RxUpdate } from 'react-icons/rx';


function ShowCaseVehicale() {
  const { visaSuccess  , setUpdatedTourPackages ,   setWhichDelete, setViewsVisaSuccess ,setPopupStatus , setDeleteConfomrationStatus} = useDreamTourismContext()

  console.log(visaSuccess);

  const handleDelete = (vs) => {
    setWhichDelete("vs")
    setViewsVisaSuccess(vs)
    setDeleteConfomrationStatus(true)
    setPopupStatus(true)
  }


  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    console.log("scrolll top");
  };

  const handleUpdate = (pkg) => {
    setUpdatedTourPackages(pkg)
    console.log(pkg);
    scrollToTop()
  }

  return (
    <section>
    <div className="container" data-aos="fade-up">

      <div className="section-title black-section-tiitle">
        <h3>Visa <span>Success</span> Story</h3>
      </div>

      <div className="row-admin-services">
        {
          visaSuccess?.map((vs , index) => (
            <div className="col-lg-4 col-md-6 d-flex align-items-stretch single-packeage" data-aos="zoom-in" data-aos-delay="100" >
              <div key={index}>
                <div className="visa-item single-packeage-first">
                  <img src={vs?.image} alt="visa-success-01" />
                </div>
              </div>
              <button onClick={() => handleDelete(vs)}  className='theme-btn visa-success-dlt-btn viewd-btn Delete-btn'><BiTrashAlt /><span>Delete</span></button>
            </div>
          ))
        }
    
      </div>


    </div>
  </section>
  )
}

export default ShowCaseVehicale